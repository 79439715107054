import React, { useContext, useState } from "react";
import { checkoutContext } from "../../context/checkoutContextProvider";
import style from "./cart.module.sass";

const INVESTMENT_PRICE_FIX_TRESHOLD = 30000;
const INVESTMENT_CAT_IDS = ["6", "7", "8", "9", "10"];

const CartMessage = () => {
    const { cart } = useContext(checkoutContext);
    const [showMore, setShowMore] = useState(false);

    const investmentProductsPrice = Object.keys(cart).reduce(
        (sumOfInvestmentProductsValue, cartItemId) => {
            if (INVESTMENT_CAT_IDS.indexOf(cart[cartItemId].category) !== -1) {
                sumOfInvestmentProductsValue +=
                    parseInt(cart[cartItemId].quantity, 10) *
                    parseInt(cart[cartItemId].itemPrice, 10);
            }
            return sumOfInvestmentProductsValue;
        },
        0
    );

    if (investmentProductsPrice < INVESTMENT_PRICE_FIX_TRESHOLD) {
        return null;
    }

    return (
        <div className={style.message}>
            {!showMore && (
                <>
                    <p>
                        Stanovení konečné ceny nakupovaných položek investičního
                        zlata/stříbra nad 30&nbsp;000,-&nbsp;Kč bude provedeno
                        až v&nbsp;okamžiku připsání platby na účet
                        prodávajícího.
                    </p>
                    <button onClick={() => setShowMore(true)}>
                        zobrazit více
                    </button>
                </>
            )}
            {showMore && (
                <p>
                    Pro zpracování objednávky nutná platba předem! Stanovení
                    konečné ceny* nakupovaných položek investičního
                    zlata/stříbra nad&nbsp;30&nbsp;000,-&nbsp;Kč bude provedeno
                    až v&nbsp;okamžiku připsání platby na účet prodávajícího.
                    Úhradu ve výši 100&nbsp;% je možné provést bankovním
                    převodem či platební kartou - platba předem. Peníze musí být
                    na účet České mincovny připsány nejdéle v&nbsp;15:00 hod.
                    (ze zákonných důvodů je hotovostní platba omezená částkou
                    270&nbsp;000&nbsp;Kč nebo ekvivalentem v&nbsp;jiné měně). V
                    opačném případě bude cena fixována v následující pracovní
                    den. Následně se vrací přeplatky, příp.&nbsp;účtuje
                    doplatek.
                    <br />
                    <br />
                    <small className={style.footnote}>
                        * fixace ceny drahého kovu dle aktuálního kurzu (více
                        informací rádi sdělíme osobně či telefonicky)
                    </small>
                </p>
            )}
        </div>
    );
};

export default CartMessage;
