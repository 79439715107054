import React from "react";

import style from "./steps.module.sass";

const stepsData = [
    {
        title: "Objednávka",
        content: "Po objednání Vám zboží nejprve rezervujeme.",
    },
    {
        title: "Zpracování objednávky",
        content: "Objednávku potvrdíme a zašleme Vám proforma fakturu s&nbsp;údaji pro platbu.",
    },
    {
        title: "Potvrzení platby",
        content:
            "Po připsání platby zboží expedujeme do 5 pracovních dnů.",
    },
    {
        title: "Doručení",
        content:
            "Zásilky posíláme jako cenné psaní od České pošty, včetně pojištění.",
    },
];

const Steps = ({ higher }) => (
    <section className={style.steps}>
        <h3>Jak to probíhá?</h3>
        <div
            className={
                higher ? style.stepsContainerHigher : style.stepsContainer
            }
        >
            {stepsData.map((step, index) => (
                <div className={style.step} key={index}>
                    <span className={style.number}>{index + 1}</span>
                    <div className={style.content}>
                        <p className={style.name}>{step.title}</p>
                        <p
                            className={style.label}
                            dangerouslySetInnerHTML={{ __html: step.content }}
                        />
                    </div>
                </div>
            ))}
        </div>
    </section>
);

export default Steps;
