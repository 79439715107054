import React, { useState, useEffect, useRef } from "react";
import accounting from "accounting";
import Img from "gatsby-image";
import produce from "immer";
import style from "./cartItem.module.sass";

import { measuringRemovingProductFromShoppingCart } from "../../utils/gtm";
import { CM_MAX_ITEM_ORDER_QUANTITY } from "../../constants";

const MIN_AMOUNT = 1;
const MAX_AMOUNT = CM_MAX_ITEM_ORDER_QUANTITY;

const CartItem = ({ item, cart, setCart }) => {
    const [amount, setAmount] = useState(item.quantity);

    const useDidUpdateEffect = amount => {
        const didMountRef = useRef(false);
        useEffect(() => {
            if (didMountRef.current) {
                const nextState = produce(cart, draftState => {
                    draftState[item.productId].quantity = amount;
                    if (amount == 0) {
                        measuringRemovingProductFromShoppingCart(
                            cart[item.productId]
                        );
                        delete draftState[item.productId];
                    }
                });
                setCart(nextState);
            } else {
                didMountRef.current = true;
            }
        }, [amount]);
    };

    useDidUpdateEffect(amount);

    const handleSetAmount = newAmount => {
        if (newAmount < MIN_AMOUNT) {
            return setAmount(MIN_AMOUNT);
        }

        if (newAmount > MAX_AMOUNT) {
            return setAmount(MAX_AMOUNT);
        }

        return setAmount(newAmount);
    };

    return (
        <>
            <div className={style.product}>
                <div className={style.productName}>
                    <Img fluid={item.featuredImage} className={style.image} />
                    <h2>{item.productName}</h2>
                </div>

                <div className={style.productAmount}>
                    <span
                        className={style.minus}
                        onClick={() => handleSetAmount(amount - 1)}
                    >
                        -
                    </span>
                    <span className={style.amount}>
                        <input
                            type="number"
                            value={item.quantity}
                            min="1"
                            max={MAX_AMOUNT}
                            readOnly
                        />
                        <span className={style.amountLabel}>Ks</span>
                    </span>
                    <span
                        className={style.plus}
                        onClick={() => handleSetAmount(amount + 1)}
                    >
                        +
                    </span>
                </div>

                <div className={style.vat}>
                    {item.vat == 0
                        ? "osvobozeno od DPH"
                        : "včetně DPH " + item.vat + " %"}
                </div>
                <div className={style.productPrice}>
                    {accounting.formatNumber(
                        item.itemPrice * amount,
                        0,
                        " ",
                        ","
                    )}
                    <span className={style.currency}>&nbsp;Kč</span>
                    <div className={style.delete} onClick={() => setAmount(0)}>
                        <svg
                            width="16"
                            height="19"
                            viewBox="0 0 16 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M6 0H10C11.6569 0 13 1.34315 13 3H16V7H15V19H1V7H0V3H3C3 1.34315 4.34315 0 6 0ZM10 2C10.5523 2 11 2.44772 11 3H5C5 2.44772 5.44772 2 6 2H10ZM4 16.1428C3.44772 16.1428 3 15.6951 3 15.1428V9C3 8.44771 3.44772 8 4 8C4.55228 8 5 8.44772 5 9V15.1428C5 15.6951 4.55228 16.1428 4 16.1428ZM8 16.1428C7.44772 16.1428 7 15.6951 7 15.1428V9C7 8.44771 7.44772 8 8 8C8.55228 8 9 8.44772 9 9V15.1428C9 15.6951 8.55228 16.1428 8 16.1428ZM11 15.1428C11 15.6951 11.4477 16.1428 12 16.1428C12.5523 16.1428 13 15.6951 13 15.1428V9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44771 11 9V15.1428Z"
                                fill="#9AAAB2"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CartItem;
