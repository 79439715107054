import React, { useContext, useEffect } from "react";
import accounting from "accounting";
import { checkoutContext } from "../../context/checkoutContextProvider";
import CartItem from "./CartItem";
import Button from "../Button";
import { cartSum } from "./cartSum";
import CheckoutButtons from "../../components/CheckoutButtons/CheckoutButtons";
import style from "./cart.module.sass";

import { measuringCheckoutSteps } from "../../utils/gtm";

const CartItems = () => {
    const { cart, setCart } = useContext(checkoutContext);
    const cartTotal = cartSum(cart);
    const emptyCart = Object.entries(cart).length === 0;

    if (emptyCart) {
        return (
            <section className={style.wrapper}>
                V košíku nemáte žádné položky.
            </section>
        );
    }

    useEffect(() => {
        measuringCheckoutSteps(cart, 1);
    }, []);

    return (
        <section className={style.wrapper}>
            <div className={style.cartHeading}>
                <span>Produkt</span>
                <span>Množství</span>
                <span>dph</span>
                <span>cena</span>
            </div>
            <div className={style.inner}>
                {cart &&
                    Object.entries(cart).map(([key, value]) => (
                        <CartItem
                            key={value.productId}
                            item={value}
                            setCart={setCart}
                            cart={cart}
                        />
                    ))}

                <div className={style.delivery}>
                    <span>Doručení Česká pošta – Cenné psaní</span>
                    <span>
                        {cartTotal.delivery}&nbsp;
                        {cartTotal.delivery === "zdarma" ? "" : "Kč"}
                    </span>
                </div>

                <div className={style.price}>
                    <span>Celkem včetně DPH</span>
                    <div className={style.total}>
                        {accounting.formatNumber(
                            cartTotal.totalPrice,
                            0,
                            " ",
                            ","
                        )}
                        <span className={style.currency}>&nbsp;Kč</span>
                    </div>
                </div>
            </div>
            <CheckoutButtons>
                <Button
                    type="link"
                    mt={50}
                    uri="/e-shop/"
                    color="outline-gold-dark"
                >
                    Zpět
                </Button>
                <Button type="link" mt={50} uri="/e-shop/delivery/">
                    Pokračovat
                </Button>
            </CheckoutButtons>
        </section>
    );
};

export default CartItems;
