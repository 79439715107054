import React from "react";
import Breadcrumb from "../../components/BreadCrumb/";
import SiteLayout from "../../components/SiteLayout";
import Steps from "../../components/Steps";
import Title from "../../components/Title";
import Block from "../../components/Block/";
import CartItems from "../../components/Cart/CartItems";
import CartMessage from "../../components/Cart/CartMessage";

const Cart = props => (
    <SiteLayout location={props.location} eshop>
        <Block cart={true}>
            <Breadcrumb
                crumbs={[
                    { label: "E-shop", link: "/e-shop" },
                    { label: "Košík", link: "/e-shop/cart" },
                ]}
                leftIndent={false}
                fullWidth
            />
            <Title type={"h1"} color={"gold"} mb={30} mt={30}>
                Košík
            </Title>
            <CartMessage />
            <CartItems />
            <Steps higher />
        </Block>
    </SiteLayout>
);

export default Cart;
